/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */

@font-face {
  font-family: 'Space Mono', monospace;
  src: url('assets/fonts/Space_Mono/SpaceMono-Regular.ttf') format('ttf');
  src: url('assets/fonts/Space_Mono/SpaceMono-Italic.ttf') format('ttf');
  src: url('assets/fonts/Space_Mono/SpaceMono-BoldItalic.ttf') format('ttf');
  src: url('assets/fonts/Space_Mono/SpaceMono-Bold.ttf') format('ttf');
}



@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* custom themes */
@import "src/theme/default.scss";
@import "src/theme/structure.scss";
@import "src/theme/typography.scss";
@import "src/theme/ionic.scss";

