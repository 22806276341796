ion-button {
  text-transform: none;
}

ion-title {
  text-align: center;
}

ion-header {
  border-bottom: 1px solid #f3c84d;
}
